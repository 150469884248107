import React, { JSXElementConstructor, ReactElement } from 'react';
import { Maybe } from '../../graphql-types';

const backgroundColor: Record<string, string> = {
  'black': 'bg-black text-white',
  'red': 'bg-red-800 text-white',
  'pink': 'bg-pink-500 text-white',
  'purple': 'bg-purple-600 text-white',
  'gray': 'bg-gray-800 text-white',
};

interface Props {
  titleQuote?: string;
  backgroundColor?: Maybe<string>;
  titleClass?: string;
  classContainer?: string;
  textClass?: string;
  textStyle?: string;
  quote?: Maybe<string> | ReactElement<any, string | JSXElementConstructor<any>>[];
  source?: Maybe<string>;
}

const Quote: React.FC<Props> = (props) => {
  const classContainer =
    props.classContainer ??
    `padding-lateral laptop-standard:px-64 mt-13 laptop-standard:mt-6 text-center
      ${props.backgroundColor ? backgroundColor[props.backgroundColor] : ''}`;
  const textClass = props.textClass ?? `py-13 laptop-standard:py-25 text-center ${props.textStyle}`;

  const title = props.titleQuote ? (
    <p className={props.titleClass ?? 'text-6xl leading-13 font-black'}>{props.titleQuote}</p>
  ) : (
    <></>
  );

  return (
    <div className={classContainer}>
      <div className="container max-width">
        {title}
        <h3 className={textClass}>
          {props.quote
            ? typeof props.quote === 'string'
              ? `“${props.quote}”`
              : props.quote
            : null}
          {props.source && <span className="font-medium">{` — ${props.source}`}</span>}
        </h3>
      </div>
    </div>
  );
};

export default Quote;
