import React, { Component, ReactNode } from 'react';
import Container from '../container';

interface Props {
  className?: string;
}

class Body extends Component<Props> {
  render(): ReactNode {
    const className = this.props.className ?? 'padding-lateral mobile:pb-25 tablet:pb-33';
    return (
      <Container>
        <div className={className}>{this.props.children}</div>
      </Container>
    );
  }
}

export default Body;
