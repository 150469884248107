import React, { useEffect, useState } from 'react';
import Button from '../button';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../container';
import { graphql, useStaticQuery } from 'gatsby';
import { GetArticlesWithToutImageQuery } from '../../../graphql-types';

const GET_ARTICLES_WITH_TOUT_IMAGE = graphql`
  query GetArticlesWithToutImage {
    allNodeArticle(
      sort: { fields: [field_date], order: DESC }
      filter: {
        field_hidden: { ne: true }
        relationships: { field_tout_image: { id: { ne: null } } }
      }
    ) {
      nodes {
        field_id
        field_date
        field_link
        field_content_type
        field_caption
        field_read_time
        title
        relationships {
          field_article_topics {
            name
          }
          field_image {
            ...MediaImageBlurred90
          }
          field_tout_image {
            ...MediaImageBlurred90
          }
          field_author {
            title
            field_author_profession
          }
        }
      }
    }
  }
`;

function randomize<ArrayItemType>(anyArray: ArrayItemType[]): ArrayItemType {
  return anyArray[Math.floor(Math.random() * anyArray.length)];
}

interface Props {
  topics?: (string | undefined | null)[];
  articleID?: string | null;
  currentArticleID?: string | null;
}

type ArticleItem = GetArticlesWithToutImageQuery['allNodeArticle']['nodes'][0];

const ArticleBanner: React.FC<Props> = ({ topics, articleID, currentArticleID }) => {
  const {
    allNodeArticle: { nodes },
  } = useStaticQuery<GetArticlesWithToutImageQuery>(GET_ARTICLES_WITH_TOUT_IMAGE);

  const [randomArticle, setRandomArticle] = useState<ArticleItem>();

  const [randomArticleByTopic, setRandomArticleByTopic] = useState<ArticleItem>();

  const [specialArticle, setSpecialArticle] = useState<ArticleItem>();

  const articlesExceptCurrent = React.useMemo(() => {
    return nodes.filter((article) => article.field_id !== currentArticleID);
  }, [nodes, currentArticleID]);

  const articlesByTopic = React.useMemo(() => {
    return articlesExceptCurrent.reduce<Record<string, ArticleItem[]>>(
      (articleDictionary, article) => {
        article.relationships?.field_article_topics?.forEach((topic) => {
          if (topic?.name) {
            if (articleDictionary[topic.name]) {
              articleDictionary[topic.name].push(article);
            } else {
              articleDictionary[topic.name] = [article];
            }
          }
        });
        return articleDictionary;
      },
      {}
    );
  }, [articlesExceptCurrent]);

  const articlesByID = React.useMemo(() => {
    return articlesExceptCurrent.reduce<Record<string, ArticleItem>>(
      (articleDictionary, article) => {
        articleDictionary[article.field_id ?? ''] = article;
        return articleDictionary;
      },
      {}
    );
  }, [articlesExceptCurrent]);

  useEffect(() => {
    const randomizedArticle =
      articlesExceptCurrent[Math.floor(Math.random() * articlesExceptCurrent.length)];
    setRandomArticle(randomizedArticle);

    if (topics) {
      const randomTopic = randomize(topics);
      if (randomTopic && articlesByTopic && articlesByTopic[randomTopic]) {
        const randomizedArticleByTopic = randomize(articlesByTopic[randomTopic]);
        setRandomArticleByTopic(randomizedArticleByTopic);
      }
    }

    if (articleID) {
      const articleFound = articlesByID[articleID];
      if (articleFound) {
        setSpecialArticle(articleFound);
      }
    }
  }, [articlesExceptCurrent, topics, articleID, articlesByTopic, articlesByID]);

  if (!randomArticle) {
    return null;
  }

  const {
    title,
    'field_id': fieldId,
    'field_link': fieldLink,
    'field_caption': fieldCaption,
    'field_content_type': fieldContentType,
    'field_read_time': fieldReadTime,
    relationships,
  } = specialArticle ?? randomArticleByTopic ?? randomArticle;
  const urlLink = fieldLink ?? `/insights/${fieldId}`;
  return (
    <Container>
      <div className="padding-lateral pt-0 mobile:pb-25 tablet:pb-45">
        <div className="border-t-2 border-gray-100 pb-6 tablet:pt-7 mobile:pt-13 laptop-standard:pt-32 laptop-standard:mx-2 font-semibold">
          <p>Insights</p>
        </div>
        <div className="flex mobile:flex-wrap-reverse laptop-standard:flex-nowrap">
          <div className="flex-initial w-full mobile:order-last laptop-standard:order-first">
            <h3 className="pt-0 pb-6 tablet:pr-0 laptop-standard:ml-2 laptop-standard:mr-10 laptop-standard-max:!text-6lg laptop-standard-max:!leading-8 laptop-wide-max:text-2xl laptop-wide-max:leading-14">
              {title}
            </h3>
            <div className="pt-0 space-y-1 mobile:pb-12 tablet:pb-12 laptop-standard:mx-2">
              <p className="mobile:iconSm laptop-standard:iconLg">
                by {relationships?.field_author?.title},{' '}
                {relationships?.field_author?.field_author_profession}
              </p>
              <p className="mobile:iconSm laptop-standard:iconLg text-gray-700">
                {fieldCaption ? (
                  fieldReadTime ? (
                    <>
                      {fieldCaption}
                      <span className="px-2">|</span>
                    </>
                  ) : (
                    fieldCaption
                  )
                ) : (
                  ''
                )}
                {fieldReadTime && `${fieldReadTime} min read`}
              </p>
            </div>
            <div className="hidden laptop-standard:flex laptop-standard:mx-2">
              <Button
                buttonTitle="Read More"
                styles="articleBannerButton"
                contentType={fieldContentType}
                internalLink={urlLink}
              />
            </div>
          </div>
          <div
            className="flex-initial relative h-4/5 w-full
             mobile:mb-10 laptop-standard:mb-0 bg-gray-700"
          >
            <GatsbyImage
              image={
                relationships?.field_tout_image?.relationships?.field_media_image?.localFile
                  ?.childImageSharp?.gatsbyImageData ??
                relationships?.field_image?.relationships?.field_media_image?.localFile
                  ?.childImageSharp?.gatsbyImageData
              }
              className="h-full w-full"
              alt={
                relationships?.field_tout_image?.field_media_image?.alt ??
                relationships?.field_image?.field_media_image?.alt ??
                ''
              }
            />
          </div>
        </div>
        <div className="mobile:flex laptop-standard:hidden">
          <Button buttonTitle="Read More" contentType={fieldContentType} internalLink={urlLink} />
        </div>
      </div>
    </Container>
  );
};

export default ArticleBanner;
