import React, { ReactElement } from 'react';
import ReactHtmlParser, { convertNodeToElement, Transform } from 'react-html-parser';
import ArticleTout from '../components/article/articleBanner';
import Body from '../components/article/body';
import Layout from '../components/layout';
import Quote from '../components/quote';
import * as styles from '../styles/article.module.css';
import { GetAllNewsQuery } from '../../graphql-types';
import Masthead from '../components/masthead';
import PageTypeContext from '../context/pageTypeContext';
import PageType from '../context/pageType';

interface Props {
  pageContext: {
    newsData: GetAllNewsQuery['allNodeNews']['nodes'][0];
  };
}

const quoteClass =
  'mobile:mx-6 tablet:mx-16 laptop-large:mx-32 laptop-large:pl-20 tablet:pl-10 tablet:py-35 mobile:pt-13 mobile:pb-13';

function removesNodeSpacing(children?: Record<any, any>[]) {
  return children?.reduce((result, child) => {
    result.push({
      ...child,
      data: child?.data?.trim?.() ?? child?.data,
    });
    return result;
  }, []);
}

function transform(node: any, index: any) {
  const needExtraSpacing = ['li'].includes(node.name);
  const needCleanUp = ['td'].includes(node.name);
  const renderedNode: ReactElement = needCleanUp
    ? convertNodeToElement(
        { ...node, children: removesNodeSpacing(node.children) },
        index,
        transform
      )
    : convertNodeToElement(node, index, transform);

  if (needExtraSpacing) {
    return (
      <>
        {renderedNode}
        {needExtraSpacing ? <br /> : null}
      </>
    );
  }
  return renderedNode;
}

function renderDOMChildren(children: any[]) {
  return children?.map((child, index) => convertNodeToElement(child, index, transform));
}

const transformBody: Transform = function (node, index) {
  if (node.type !== 'tag') {
    return convertNodeToElement(node, index, transform);
  }

  if (node.name === 'img' && process.env.GATSBY_DRUPAL_URL) {
    if (node?.attribs?.src && node.attribs.src.startsWith('/sites/default')) {
      node.attribs.src = process.env.GATSBY_DRUPAL_URL.concat(node.attribs.src);
    }
  } else if (node.name === 'blockquote') {
    return (
      <Quote
        classContainer="bg-yellow-500 text-black px-4"
        quote={renderDOMChildren(node?.children)}
        textClass={quoteClass}
        key={`blockquote-${index}`}
      />
    );
  } else if (['p', 'ul'].includes(node.name)) {
    node.attribs.class = (node.attribs.class ?? '').concat(' body1');
  }

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'img', 'p', 'ul', 'hr', 'table'].includes(node.name)) {
    let extraStyle = '';
    switch (node.name) {
      case 'ul':
        extraStyle = 'list-decimal space-y-6 mobile:mx-4 tablet:mx-8 laptop-standard:mx-16';
        break;
      default:
        break;
    }

    const childrenRendered = convertNodeToElement(node, index, transform);

    return (
      <Body
        key={`${node.name}-${index}`}
        className={`px-10 laptop-standard:px-40 laptop-large:px-56 laptop-wide:px-64 desktop-standard:px-65 desktop-large:px-74 block overflow-hidden ${extraStyle} ${styles.paragraphspacing}`}
      >
        {extraStyle ? <div className={extraStyle}>{childrenRendered}</div> : childrenRendered}
      </Body>
    );
  }

  return convertNodeToElement(node, index, transform);
};

const NewsBuilder: React.FC<Props> = ({ pageContext }) => {
  const { newsData } = pageContext;

  return (
    <PageTypeContext.Provider value={{ pageType: PageType.News }}>
      <Layout
        seo={{
          'title': newsData?.title ?? '',
          'author': 'Deutsch LA',
          'keywords': 'read, deutsch',
        }}
      >
        {newsData.relationships?.field_masthead_reference && (
          <Masthead mastheadData={newsData.relationships?.field_masthead_reference} />
        )}
        {newsData?.body?.processed &&
          ReactHtmlParser(newsData.body.processed, {
            transform: transformBody,
          })}
        <div className="mobile:pb-20 tablet:pb-35"></div>
        <ArticleTout />
      </Layout>
    </PageTypeContext.Provider>
  );
};

export default NewsBuilder;
